import React from 'react'
import Contact from '../contact/Contact'
import Header from '../Header/Header'

const ContactPage = () => {
  return (
    <>
      <Header />
      <Contact />  
    </>
    )
}

export default ContactPage