import React from 'react'
import Works from '../works/Works'
import Header from '../Header/Header'
// import Partnerships from '../works/Partnerships'

const OurWorks = () => {
  return (
    <>
    <Header />
    <Works />
    {/* <Partnerships /> */}
    </>
  )
}

export default OurWorks